import React, { useState } from "react";
import "../components/oper.scss";
import ApplyForm from "./ApplyForm";

const Oppertunity = () => {
  return (
    <div className="job-listings">
      <JobListing
        title="Sr.Business development executive"
        location="Coimbatore, Tamilnadu"
        experience="3+ years"
        jobType="Full time"
        description={[
          "Identify and qualify leads through research, networking, and cold calling.",
          "Develop and maintain relationships with key decision-makers to understand their business needs and challenges.",
          "Conduct product demonstrations and presentations to showcase the features and benefits of our software solutions.",
          "Collaborate with the marketing team to develop targeted sales strategies and campaigns.",
          "Prepare and deliver persuasive sales proposals and negotiate contracts to close deals.",
          "Provide ongoing support and guidance to clients to ensure satisfaction and retention.",
          "Stay up-to-date on industry trends, competitors, and emerging technologies to effectively position our products in the market.Track and report on sales activities, pipeline, and performance metrics using CRM software.",
        ]}
        skills={[
          "Communication and Interpersonal Skills.",
          "Sales and Negotiation Skills.",
          "Market Knowledge and Analytical Thinking.",
          "Time Management and Goal Orientation.",
        ]}
      />

      {/* <JobListing
        title="Sr.PHP Developer"
        location="Coimbatore, Tamilnadu"
        experience="3+ years"
        jobType="Full time"
        description={[
          "Develop, test, and maintain secure PHP-based applications.",
          "Design and build efficient, reusable, and scalable PHP modules and web applications.",
          "Collaborate with frontend developers to integrate user-facing elements with server-side logic.",
          "Write and maintain clear, scalable, and well-documented code.",
          "Troubleshoot, test, and debug applications to optimize performance.",
          "Work with other team members and stakeholders to define and implement new features.",
          "Follow industry trends and best practices to ensure code quality and application security.",
        ]}
        skills={[
          "Proficiency in PHP and object-oriented programming (OOP) principles.",
          "Experience with PHP frameworks (e.g., Laravel, CodeIgniter,).",
          "Strong understanding of HTML, CSS, JavaScript, and AJAX.",
          "Familiarity with SQL and relational databases (e.g., MySQL, PostgreSQL).",
          "Knowledge of APIs (RESTful and SOAP) and experience integrating third-party services.",
          "Basic understanding of version control systems (e.g., Git).",
          "Attention to detail and commitment to producing high-quality code.",
          "Strong problem-solving and debugging skills.",
        ]}
      /> */}
    </div>
  );
};

const JobListing = ({
  title,
  location,
  experience,
  jobType,
  description,
  skills,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="job-listing">
      <h2>{title}</h2>
      <p>
        <strong>Location:</strong> {location}
      </p>
      <p>
        <strong>Experience:</strong> {experience}
      </p>
      <p>
        <strong>Job Type:</strong> {jobType}
      </p>

      {isExpanded && (
        <>
          <h3>Description</h3>
          <ul>
            {description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          <h3>Required Skills</h3>
          <ul>
            {skills.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          <ApplyForm title={title} />
        </>
      )}
      <div className="two-button">
        <button className="details-btn" onClick={toggleDetails}>
          {isExpanded ? "Show less details" : "View all details"}
        </button>
      </div>
    </div>
  );
};

export default Oppertunity;
