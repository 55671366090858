import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";
import ScrollToTop from "./hooks/ScrollToTop";
import "./index.css";
import { Career } from "./pages/Career";
import { Helmet } from "react-helmet";
import { Popover } from "@mui/material";
import { ToastContainer } from "react-toastify";

const Home = lazy(() => import("./pages/Home"));
const Products = lazy(() => import("./pages/Products"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Service = lazy(() => import("./pages/Service"));
const ViewForm = lazy(() => import("./admin/viewForm"));
const Pos = lazy(() => import("./pages/products/Pos"));
const Rms = lazy(() => import("./pages/products/Rms"));
const Erp = lazy(() => import("./pages/products/Erp"));
const Crm = lazy(() => import("./pages/products/Crm"));
const Scm = lazy(() => import("./pages/products/Scm"));
const Inventory = lazy(() => import("./pages/products/Inventory"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title>
          {" "}
          Become a Retail Rockstar with Planogram Software (POG) for Store
          Layout Optimization{" "}
        </title>
        <meta
          name="description"
          content=" Interested in Learning About Software Development? Explore the latest insights, trends, and updates in software development on our blog. Stay informed and learn about new technologies, best practices, and industry innovations to enhance your knowledge. Read our software development blogs now!"
        ></meta>
        <meta
          name="keywords"
          content=" best software related blogs, blogs about software development, blogs about pos software, blogs about retail softwares, blogs about planogram "
        ></meta>
      </Helmet>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/software-development-company-coimbatore"
            element={<About />}
          />
          <Route path="/join-our_team" element={<Career />} />
          <Route path="/products" element={<Products />} />
          <Route path="/point-of-sale-software" element={<Pos />} />
          <Route path="/restaurant-management-system" element={<Rms />} />
          <Route
            path="/enterprise-resource-planning-systems"
            element={<Erp />}
          />
          <Route path="/crm-relationship-management" element={<Crm />} />
          <Route path="/supply-chain-management" element={<Scm />} />
          <Route path="/inventory-management" element={<Inventory />} />
          <Route
            path="/software-development-services-coimbatore"
            element={<Service />}
          />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/viewform" element={<ViewForm />} />
          <Route path="/*" element={<Products />} />
        </Routes>
        <ToastContainer />
      </Suspense>
      <Popover />
    </BrowserRouter>
  );
}

export default App;
